var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"transparent","flat":""}},[_c('DataView',{attrs:{"title":"Krankenhaussituation Städteregion Aachen","loading":_vm.loading,"data":_vm.data,"hide-muni-selector":"","keyAttributes":_vm.keyAttributes,"attribute":_vm.attribute,"date":_vm.date},scopedSlots:_vm._u([{key:"graphs",fn:function(){return [_c('Panel',{attrs:{"title":"freie Betten","showTrends":false,"matomoAttribute":"beds","attribute":"freeBeds","data":_vm.data,"tableAttributes":[
                    { value: 'allBeds', text: 'alle Betten' },
                    { value: 'occupiedBeds', text: 'belegte Betten' },
                    {
                        value: 'avgAllBeds',
                        text: 'Durchschnitt, alle Betten'
                    },
                    {
                        value: 'avgOccupiedBeds',
                        text: 'Durchschnitt, belegte Betten'
                    }
                ],"tabs":[{ id: 'daily', title: 'täglich' }]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Anzahl der Betten auf den Intensivstationen der Städteregion Aachen ")]},proxy:true},{key:"tab.daily",fn:function(){return [_c('BedChart',{attrs:{"labels":_vm.data.dates,"data":_vm.data,"name":"Betten"}})]},proxy:true}])}),_c('Panel',{attrs:{"title":"COVID-19-Fälle","matomoAttribute":"covid","attribute":"covid19Cases","data":_vm.data,"tableAttributes":[
                    { value: 'covid19Cases', text: 'freie Betten' },
                    {
                        value: 'avgCovid19Cases',
                        text: 'Durchschnitt der letzten 7 Tage'
                    }
                ],"tabs":[{ id: 'daily', title: 'Täglich' }]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Anzahl der Betten auf Intensivstationen, die mit COVID-19-Patienten belegt sind. ")]},proxy:true},{key:"tab.daily",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.covid19Cases,"avgs":_vm.data.avgCovid19Cases,"name":"COVID-19-Fälle"}})]},proxy:true}])}),_c('Panel',{attrs:{"title":"COVID-19-Fälle, beatmet","matomoAttribute":"ventilated","attribute":"ventilatorCases","data":_vm.data,"tableAttributes":[
                    { value: 'ventilatorCases', text: 'COVID-19, beatmet' },
                    {
                        value: 'avgVentilatorCases',
                        text: 'Durchschnitt der letzten 7 Tage'
                    }
                ],"tabs":[{ id: 'daily', title: 'Täglich' }]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Anzahl der Betten auf Intensivstationen, die mit COVID-19-Patienten belegt sind, die beatmet werden müssen. ")]},proxy:true},{key:"tab.daily",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.ventilatorCases,"avgs":_vm.data.avgVentilatorCases,"name":"COVID-19-Fälle, beatmet"}})]},proxy:true}])})]},proxy:true}])}),_c('v-card-text',{staticClass:"mb-5"},[_c('v-list',[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"#1B9AAA"}},[_vm._v("fa fa-info-circle")])],1),_c('v-list-item-content',{staticClass:"caption "},[_vm._v(" Diese Daten werden vom "),_c('a',{attrs:{"target":"_blank","href":"https://www.intensivregister.de/#/index"}},[_vm._v(" DIVI-Intensivregister")]),_vm._v(" bereitgestellt. Dort findet man auch weitere Daten, Zeitreihen und Downloads. ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }