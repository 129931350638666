var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-8 mt-10 mx-0 px-0 mx-md-6",attrs:{"color":"#fff","tile":""}},[(_vm.hideHeader)?_c('v-card-title',{staticClass:"text-h6 text-md-h5 font-weight-bold primary--text",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_c('v-card-title',{staticClass:"text-h6 text-md-h5 font-weight-bold primary--text",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.title)+": "),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s((_vm.todayValue || 0).toLocaleString("de-DE")))]),_vm._t("trends",[(_vm.showTrends)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"ml-3 mr-3 pa-0 ttip"},on),[_c('small',{staticClass:"text-h6 text-md-h6 caption"},[_vm._v(_vm._s(_vm.diffYesterday)+" ")])])]}}],null,false,3800699658)},[_vm._v(" Unterschied zum Vortag ")]):_vm._e(),(_vm.showTrends)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',{staticClass:"ttip"},[_c('v-icon',_vm._g({staticClass:"pa-0 pl-1 pr-5 ma-0 trend-icon",style:(_vm.trend.rotate),attrs:{"color":_vm.trend.color,"size":_vm.$vuetify.breakpoint.smAndUp ? 30 : 20}},on),[_vm._v(_vm._s(_vm.trend.icon))])],1)]}}],null,false,3096857461)},[_c('span',[_vm._v("Der Langzeittrend ist die Differenz des Wertes von vor 7 Tagen verglichen mit dem aktuellen Wert.")])]):_vm._e()])],2),_c('v-card-text',{staticClass:"body-2 pr-10 pl-5",staticStyle:{"max-width":"1024px"}},[_vm._t("description")],2),_c('v-card-text',{class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'px-0'},[_c('v-tabs',{ref:"tabs",staticClass:"graph-tabs",attrs:{"background-color":"#fff","active-class":"graph-tab-active"},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},_vm._l((_vm.allTabs),function(tab){return _c('v-tab',{key:tab.id,staticClass:"body-2 font-weight-bold",on:{"click":function($event){_vm.$matomo &&
                        _vm.$matomo.trackEvent(
                            'Corona',
                            'graphclick-' + tab.id,
                            _vm.matomoAttribute
                        )}}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},[_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab.id,class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'py-4 pa-0',attrs:{"transition":"none"}},[_vm._t(("tab." + (tab.id)))],2)}),(!_vm.hideTable)?_c('v-tab-item',{key:"data",attrs:{"transition":"none"}},[_c('v-card',{attrs:{"flat":"","color":"#fff"}},[_c('v-card-text',{staticClass:"caption primary--text"},[_vm._v(" Klicke auf die Kopfzeilen, um die Daten entsprechend zu sortieren. ")]),_c('v-data-table',{attrs:{"fixed-header":"","id":"data-table","must-sort":"","sort-by":"dates","sort-desc":"","dense":"","disable-pagination":"","hide-default-footer":"","headers":_vm.tableHeaders,"items":_vm.tableData,"height":_vm.$vuetify.breakpoint.mdAndUp ? 500 : 280},scopedSlots:_vm._u([{key:"item.dates",fn:function(item){return [_vm._v(" "+_vm._s(_vm._f("dateformat")(item.item.dates))+" ")]}}],null,false,1273032604)})],1)],1):_vm._e(),(_vm.showAbout)?_c('v-tab-item',{key:"about",attrs:{"transition":"none"}},[_c('v-card',{attrs:{"flat":"","color":"#fff"}},[_c('v-card-text',[_vm._t("tab.about")],2)],1)],1):_vm._e()],2)],1),_c('v-card-text',{staticClass:"caption pr-10 pl-5 py-0",staticStyle:{"max-width":"1024px"}},[_vm._t("footer")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }