var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataView',{attrs:{"title":"Todesfälle","loading":_vm.loading,"muni":_vm.muni,"data":_vm.data,"keyAttributes":_vm.keyAttributes,"attribute":_vm.attribute,"downloadUrl":_vm.downloadUrl,"date":_vm.date},scopedSlots:_vm._u([{key:"graphs",fn:function(){return [_c('Panel',{attrs:{"title":"Todesfälle","matomoAttribute":"recovered","attribute":"recoveredCases","data":_vm.data,"tableAttributes":[
                { value: 'newDeaths', text: 'Todesfälle' },
                {
                    value: 'avgNewDeaths',
                    text: 'Durchschnitt der letzten 7 Tage'
                },
                { value: 'cumDeaths', text: 'kumulierte Fälle' }
            ],"tabs":[
                { id: 'daily', title: 'Täglich' },
                { id: 'cum', title: 'Kumuliert' }
            ]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Die Anzahl der Personen, die an oder mit COVID-19 verstorben sind. ")]},proxy:true},{key:"tab.daily",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.newDeaths,"avgs":_vm.data.avgNewDeaths,"name":"Todesfälle"}})]},proxy:true},{key:"tab.cum",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.cumDeaths,"name":"kumuliert"}})]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }