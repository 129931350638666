var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.loading)?_c('v-card',{staticClass:"text-center mt-10 pt-10",attrs:{"flat":"","color":"transparent"}},[_c('div',{staticClass:"lds-hourglass"})]):_c('v-card',{attrs:{"tile":"","flat":"","color":"#f8f8f8"}},[_c('v-card-text',[_c('h1',{staticClass:"text-xs-h7 text-md-h3 pb-3 font-weight-bold primary--text"},[_vm._v(" Coronavirus in der Städteregion Aachen ")])]),_c('v-card-text',{staticClass:"pb-0 caption"},[_vm._v(" Stand: "+_vm._s(_vm.date)+" ")]),_c('v-card-title',{staticClass:"text-xs-h7 text-lg-h4 pb-0 font-weight-bold"},[_vm._v(" Übersicht Städteregion Aachen"),_c('br')]),(!_vm.loading)?_c('v-card-text',{staticClass:"py-3 px-1"},[_c('Indicator',{attrs:{"value":_vm.sr.today.rollingRate,"title":"Inzidenz","description":"Die Inzidenz gibt an, wie viele Personen pro 100.000 Einwohner in den letzten 7 Tagen positiv auf COVID-19 getestet wurden","trend":_vm.computeTrend(_vm.sr.today.rollingRate, 50, 200)}}),_c('Indicator',{attrs:{"value":((Math.round(_vm.sr.today.rollingRatePerc * 100, 2)) + "%"),"title":"Inzidenz-Wachstum","description":"Die Wachstumsrate der Inzidenz. Verglichen wird die letzte Woche mit der Vorwoche. Dies entspricht dem 7-Tage-R-Wert.","trend":_vm.computeTrend(_vm.sr.today.rollingRatePerc * 100, -5, 0),"middleColor":"#d0d0d0"}}),_c('Indicator',{attrs:{"value":_vm.divi.today.freeBeds,"title":"freie Betten","description":"Freie Intensivbetten in der Städteregion Aachen","trend":_vm.divi.today.freeBeds > 10 ? 1 : -1}})],1):_vm._e(),(!_vm.loading)?_c('v-card-text',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('MiniChartNew',{attrs:{"start":150,"date":_vm.sr.date,"today":_vm.sr.today.rollingRate,"weekChange":Math.round(_vm.sr.trend.rollingRate7DayChange),"weekChangePercent":Math.round(
                                _vm.sr.trend.rollingRate7DayChangePercent * 100
                            ),"description":"Positiv getestete Personen der letzten 7 Tage pro 100.000 Einwohner","title":"7-Tage-Inzidenz","attribute":"incidence","data":_vm.sr.rollingRate}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('MiniChartNew',{attrs:{"start":150,"date":_vm.sr.date,"today":_vm.sr.today.cumCases,"weekChange":Math.round(_vm.sr.trend.cumCases7DayChange),"weekChangePercent":Math.round(
                                _vm.sr.trend.cumCases7DayChangePercent * 100
                            ),"description":"Positiv getestete Personen","title":"Fälle","attribute":"positive","data":_vm.sr.cumCases,"sum":"","sumWeek":_vm.sr.trend.cumCases7DaySum,"sum2Week":_vm.sr.trend.cumCases14DaySum}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('MiniChartNew',{attrs:{"start":150,"date":_vm.sr.date,"today":_vm.sr.today.activeCases,"weekChange":Math.round(_vm.sr.trend.activeCases7DayChange),"weekChangePercent":Math.round(
                                _vm.sr.trend.activeCases7DayChangePercent * 100
                            ),"description":"Aktive Fälle","title":"Aktive Fälle","attribute":"active","data":_vm.sr.activeCases}})],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-card-title',{staticClass:"text-xs-h7 text-lg-h4 pb-0 pt-10 font-weight-bold"},[_vm._v(" Übersicht Stadt Aachen "),_c('v-spacer')],1),(!_vm.loading)?_c('v-card-text',{staticClass:"py-3 px-1"},[_c('Indicator',{attrs:{"value":_vm.aachen.today.rollingRate,"title":"Inzidenz","description":"Die Inzidenz gibt an, wie viele Personen pro 100.000 Einwohner in den letzten 7 Tagen positiv auf COVID-19 getestet wurden","trend":_vm.computeTrend(_vm.aachen.today.rollingRate, 50, 200)}}),_c('Indicator',{attrs:{"value":((Math.round(_vm.aachen.today.rollingRatePerc * 100, 2)) + "%"),"title":"Inzidenz-Wachstum","description":"Die Wachstumsrate der Inzidenz. Verglichen wird die letzte Woche mit der Vorwoche. Dies entspricht dem 7-Tage-R-Wert.","trend":_vm.computeTrend(_vm.aachen.today.rollingRatePerc * 100, -5, 0),"middleColor":"#d0d0d0"}})],1):_vm._e(),(!_vm.loading)?_c('v-card-text',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('MiniChartNew',{attrs:{"date":_vm.aachen.date,"muni":"aachen","today":_vm.aachen.today.rollingRate,"weekChange":Math.round(_vm.aachen.trend.rollingRate7DayChange),"weekChangePercent":Math.round(
                                _vm.aachen.trend.rollingRate7DayChangePercent *
                                    100
                            ),"description":"Infektionen der letzten 7 Tage pro 100.000 Einwohner","title":"7-Tage-Inzidenz","attribute":"incidence","data":_vm.aachen.rollingRate}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('MiniChartNew',{attrs:{"date":_vm.aachen.date,"today":_vm.aachen.today.cumCases,"weekChange":Math.round(_vm.aachen.trend.cumCases7DayChange),"weekChangePercent":Math.round(
                                _vm.aachen.trend.cumCases7DayChangePercent * 100
                            ),"description":"Positiv getestete Personen","title":"Fälle","attribute":"positive","data":_vm.aachen.cumCases,"sum":"","sumWeek":_vm.aachen.trend.cumCases7DaySum,"sum2Week":_vm.aachen.trend.cumCases14DaySum}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('MiniChartNew',{attrs:{"date":_vm.aachen.date,"today":_vm.aachen.today.activeCases,"weekChange":Math.round(_vm.aachen.trend.activeCases7DayChange),"weekChangePercent":Math.round(
                                _vm.aachen.trend.activeCases7DayChangePercent *
                                    100
                            ),"description":"Aktive Fälle","title":"Aktive Fälle","attribute":"active","data":_vm.aachen.activeCases}})],1)],1)],1):_vm._e(),(!_vm.loading)?_c('v-card-text',{staticClass:"text-right"},[_vm._v(" Datenquelle: "),_c('a',{attrs:{"target":"_blank","href":"https://offenedaten.aachen.de/dataset/aktuelle-lage-zum-corona-virus"}},[_vm._v("Open Data Portal der Stadt Aachen")]),_vm._v(". ")]):_vm._e(),_c('v-divider'),(!_vm.loading)?_c('v-card-text',{staticClass:"mt-10"},[_c('h3',{staticClass:"text-h6 text-md-h4 black--text text-uppercase my-3"},[_vm._v(" Tabellarische Übersicht ")]),_c('div',{staticClass:"pb-3"},[_vm._v(" Klicke auf eine Kommune, um weitere Informationen zu erhalten ")]),_c('OverviewTable',{attrs:{"data":_vm.overview}}),_c('v-card-text',{staticClass:"caption text-right"},[_vm._v(" Die Pfeile "),_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("fa fa-chevron-down")]),_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("fa fa-chevron-up")]),_vm._v(" geben den 7-Tage-Trend wieder. ")],1)],1):_vm._e(),_c('v-card-text',{staticClass:"mt-10"},[_c('h3',{staticClass:"text-h6 text-md-h4 black--text text-uppercase my-3"},[_vm._v(" Intensivbetten in der Städteregion Aachen ")]),_c('hospital-table',{attrs:{"data":_vm.divi}})],1),_c('v-divider'),_c('v-card-text',{staticClass:"mb-5"},[_c('v-list',[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"large":"","color":"#1B9AAA"}},[_vm._v("fa fa-info-circle")])],1),_c('v-list-item-content',{staticClass:"caption text-md-body-1 "},[_vm._v(" Aktuelle Informationen zum Corona-Virus inklusive der gerade gültigen Verordnungen der Städteregion Aachen findet man unter "),_c('b',[_c('a',{attrs:{"target":"_blank","href":"https://www.staedteregion-aachen.de/corona"}},[_vm._v(" www.staedteregion-aachen.de/corona")])])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }