import { render, staticRenderFns } from "./OverviewTable.vue?vue&type=template&id=24b7345c&"
import script from "./OverviewTable.vue?vue&type=script&lang=js&"
export * from "./OverviewTable.vue?vue&type=script&lang=js&"
import style0 from "./OverviewTable.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBadge,VCard,VIcon,VSimpleTable})
