var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"#fff","tile":"","to":{ name: 'cases', params: { muni: _vm.muni } }},on:{"!click":function($event){_vm.$matomo && _vm.$matomo.trackEvent('Corona', 'minichart-click', _vm.title)}}},[_c('v-card-title',{staticClass:"text-h5 font-weight-bold text-uppercase"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',{staticClass:"mb-0 pt-0"},[_vm._v(" Aktueller Wert "),_c('small',[_vm._v("(vor 7 Tagen)")]),_c('div',{staticClass:"value"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.sum)?_c('span',_vm._g({staticClass:"ttip mr-4"},on),[_vm._v(_vm._s(_vm.today)+" "),_c('small',[_vm._v("("+_vm._s(_vm.today - _vm.sumWeek)+")")])]):_c('span',_vm._g({staticClass:"ttip mr-4"},on),[_vm._v(_vm._s(_vm.today)+" "),_c('small',[_vm._v("("+_vm._s(_vm.today - _vm.weekChange)+")")])])]}}])},[_vm._v(" "+_vm._s(_vm.description)+", Stand: "+_vm._s(_vm.dateFormatted)+" ")]),(!_vm.sum)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"small":"","label":"","color":_vm.trend.chipColor,"text-color":_vm.trend.color}},on),[_c('v-icon',{staticClass:"pr-3",attrs:{"color":_vm.trend.color,"size":16}},[_vm._v(_vm._s(_vm.trend.icon))]),_vm._v(" "+_vm._s(_vm.weekChange)+" ("+_vm._s(_vm.weekChangePercent)+"%) ")],1)]}}],null,false,3631209823)},[_c('span',[_vm._v("Differenz zum Wert vom "+_vm._s(_vm.lastWeekDateFormatted)+": "+_vm._s(_vm.today - _vm.weekChange))])]):_vm._e()],1)]),(_vm.sum)?_c('v-card-text',{staticClass:"mt-0 pt-2"},[_vm._v(" Summe der letzten/vorletzten 7 Tage "),_c('div',{staticClass:"value small"},[(_vm.sum)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"ttip mr-4"},on),[_vm._v(_vm._s(_vm.sumWeek)+" / "+_vm._s(_vm.sum2Week))])]}}],null,false,2607622556)},[_vm._v(" Die aufsummierten Werte der letzten 7 Tage vom "+_vm._s(_vm.lastWeekDateFormatted)+" bis "+_vm._s(_vm.dateFormatted)+" sowie der 7 Tage davor. ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300","color":"rgba(0,0,0,1)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"small":"","label":"","color":_vm.trend.chipColor,"text-color":_vm.trend.color}},on),[_c('v-icon',{staticClass:"pr-3",attrs:{"color":_vm.trend.color,"size":16}},[_vm._v(_vm._s(_vm.trend.icon))]),_vm._v(" "+_vm._s(_vm.weekChange)+" ("+_vm._s(_vm.weekChangePercent)+"%) ")],1)]}}],null,false,3631209823)},[_c('span',[_vm._v("Der Unterschied der Summe der letzten 7 Tage zur Summe der vorletzten 7 Tage.")])])],1)]):_vm._e(),_c('v-card-text',[_c('v-card',{attrs:{"color":"#fff","flat":"","height":150}},[_c('v-chart',{ref:"bar",attrs:{"option":_vm.options,"init-options":_vm.initOptions,"autoresize":""}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"primary--text font-weight-bold",attrs:{"small":"","color":"gray","depressed":"","to":{ name: 'cases', params: { muni: _vm.muni } }}},[_vm._v("Alle Daten ansehen")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }