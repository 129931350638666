var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataView',{attrs:{"title":"Genesene","loading":_vm.loading,"muni":_vm.muni,"data":_vm.data,"keyAttributes":_vm.keyAttributes,"attribute":_vm.attribute,"downloadUrl":_vm.downloadUrl,"date":_vm.date},scopedSlots:_vm._u([{key:"graphs",fn:function(){return [_c('Panel',{attrs:{"title":"Genesene","matomoAttribute":"recovered","attribute":"recoveredCases","data":_vm.data,"tableAttributes":[
                { value: 'newRecovered', text: 'Genesene' },
                {
                    value: 'avgNewRecovered',
                    text: 'Durchschnitt der letzten 7 Tage'
                },
                { value: 'cumRecovered', text: 'kumulierte Fälle' }
            ],"tabs":[
                { id: 'daily', title: 'Täglich' },
                { id: 'cum', title: 'Kumuliert' }
            ]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Die Anzahl der Personen, die an COVID-19 genesen sind. ")]},proxy:true},{key:"tab.daily",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.newRecovered,"avgs":_vm.data.avgNewRecovered,"name":"Genesene"}})]},proxy:true},{key:"tab.cum",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.cumRecovered,"name":"kumuliert"}})]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }