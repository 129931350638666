var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataView',{attrs:{"title":"Fallzahlen","loading":_vm.loading,"muni":_vm.muni,"data":_vm.data,"keyAttributes":_vm.keyAttributes,"attribute":_vm.attribute,"downloadUrl":_vm.downloadUrl,"date":_vm.date},scopedSlots:_vm._u([{key:"graphs",fn:function(){return [_c('Panel',{attrs:{"title":"7-Tage-Inzidenz","matomoAttribute":"incidence","attribute":"rollingRate","data":_vm.data,"tableAttributes":[
                    { value: 'rollingRate', text: '7-Tage-Inzidenz' },
                    {
                        value: 'avgRollingRate',
                        text: 'Durchschnitt der letzten 7 Tage'
                    },
                    {
                        value: 'rollingRatePerc',
                        text: 'Prozentuale Änderung'
                    }
                ],"tabs":[
                    { id: 'daily', title: 'Täglich' },
                    { id: 'percent', title: 'Änderung in Prozent' }
                ]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Die Sieben-Tage-Inzidenz gibt an, wie viele Personen in den letzten 7 Tagen positiv auf COVID-19 getestet wurden. Damit die Daten vergleichbar sind, wird die Zahl der Neuinfektionen je 100.000 Einwohner berechnet. Die prozentuale Änderung wird anhand des vorherigen 7-Tage-Zeitraums berechnet. ")]},proxy:true},{key:"tab.daily",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.rollingRate,"avgs":_vm.data.avgRollingRate,"name":"7-Tage-Inzidenz","showLines":"","showVisualMap":"rollingRate"}})]},proxy:true},{key:"tab.percent",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.rollingRatePerc,"name":"Prozentuale Änderung","startZoom":"2020-12-15"}})]},proxy:true}])}),_c('Panel',{attrs:{"title":"Neue Fälle","matomoAttribute":"cases","attribute":"newCases","data":_vm.data,"tableAttributes":[
                    { value: 'newCases', text: 'Neue Fälle' },
                    {
                        value: 'avgNewCases',
                        text: 'Durchschnitt der letzten 7 Tage'
                    },
                    { value: 'cumCases', text: 'kumulierte Fälle' }
                ],"tabs":[
                    { id: 'daily', title: 'Täglich' },
                    { id: 'cum', title: 'Kumuliert' }
                ]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Die Anzahl der neu an COVID-19 positiv getesteten Personen pro Tag. ")]},proxy:true},{key:"tab.daily",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.newCases,"avgs":_vm.data.avgNewCases,"name":"Neue Fälle"}})]},proxy:true},{key:"tab.cum",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.cumCases,"name":"kumuliert"}})]},proxy:true}])}),_c('Panel',{attrs:{"title":"4-Tage-R-Wert","matomoAttribute":"rvalue","attribute":"r4","showTrends":false,"data":_vm.data,"tableAttributes":[
                    { value: 'r4', text: 'R-Wert 4 Tage' },
                    { value: 'r7', text: 'R-Wert 7 Tage' }
                ],"tabs":[
                    { id: 'r4', title: 'R-Wert 4 Tage' },
                    { id: 'r7', title: 'R-Wert 7 Tage' }
                ]},scopedSlots:_vm._u([{key:"trends",fn:function(){return [_c('span',{staticClass:"pl-3"},[_c('small',[_vm._v("(7-Tage: "+_vm._s(_vm.data.today['r7'].toLocaleString("de-DE"))+")")])])]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Die Reproduktionszahl, auch R-Wert oder R-Zahl genannt, gibt an, wie viele Menschen eine infizierte Person in einer bestimmten Zeiteinheit im Mittel ansteckt. Liegt der Wert über 1, dann steigt die Zahl der Neuinfektionen, die Krankheit breitet sich also weiter aus. Ist sie kleiner als 1, gibt es immer weniger Neuinfektionen, die Epidemie läuft also aus.")]},proxy:true},{key:"tab.r4",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.r4,"showVisualMap":"r","name":"R-Wert 4 Tage"}})]},proxy:true},{key:"tab.r7",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.r7,"showVisualMap":"r","name":"R-Wert 7 Tage"}})]},proxy:true}])}),_c('Panel',{attrs:{"title":"Aktive Fälle","matomoAttribute":"cases","attribute":"activeCases","data":_vm.data,"tableAttributes":[
                    { value: 'activeCases', text: 'Aktive Fälle' },
                    {
                        value: 'avgActiveCases',
                        text: 'Durchschnitt der letzten 7 Tage'
                    }
                ],"tabs":[{ id: 'daily', title: 'Täglich' }]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Die Anzahl der aktuell als infiziert geltenden Personen. ")]},proxy:true},{key:"tab.daily",fn:function(){return [_c('Chart',{attrs:{"labels":_vm.data.dates,"data":_vm.data.activeCases,"avgs":_vm.data.avgActiveCases,"name":"Aktive Fälle"}})]},proxy:true}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }